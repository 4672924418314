/*
    Personal Auto API Controller
*/

frappe.provide("rater.auto");

$.extend(rater.auto, {
    get_vin_details: function(vin){
        rater.auto.get_data("get_vin_details", {vin: vin});
    },

    get_years: function(){
        return rater.auto.get_data("get_years", {});
    },

    get_makes: function(year){
        return rater.auto.get_data("get_makes", {year: year});
    },

    get_models: function(year, make){
        let args = {
            year: year,
            make: make
        };
        return rater.auto.get_data("get_models", args);
    },

    get_model_details: function(year, make, model){
        let args = {
            year: year,
            make: make,
            model: model
        };
        return rater.auto.get_data("get_model_details", args);
    },

    get_data: function(method, args){
        return new Promise((reoslve, reject)=>{
            frappe.call({
                method: "bond_assets.rater_api.auto.get_data",
                args:{
                    method: method,
                    args: args,
                },
                callback: (res)=>{
                    reoslve(res);
                },
                error: ()=>{
                    reject();
                }
            });
        });
    }
});